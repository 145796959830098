<template>
  <div class="flex relative w-auto"
       v-click-outside="() => {
         showUserDropdown = false;
       }
         ">
    <div class="flex flex-row items-center justify-center cursor-pointer select-none"
         @click.native="
           showUserDropdown =
           !showUserDropdown
           ">
      <NuxtImg :src="authManager.authUserAvatarUrl.value"
           class="border-2 rounded-full w-10 h-10 border-a-neutral-dim mx-1"
           width="35"
           height="35"
           alt="" />
      <span class="font-semibold text-xs">{{ authManager.authUserFullName.value }}</span>
      <Icon :name="showUserDropdown ? 'tabler:chevron-up' : 'tabler:chevron-down'"
            class="text-2xl" />
    </div>

    <div v-show="showUserDropdown"
         class="flex flex-col absolute -bottom-28 w-44 text-sm border left-0 p-2 bg-white text-a-neutral rounded shadow-lg z-50">
      <nuxt-link :to="{ name: 'dashboard' }"
                 class="hover:bg-a-secondary hover:text-white py-1 cursor-pointer rounded select-none">
        <Icon name="mingcute:dashboard-3-fill"
              class="px-2" /><span>Dashboard</span>
      </nuxt-link>
      <nuxt-link :to="{
        name: 'dashboard-profile',
      }"
                 class="hover:bg-a-secondary hover:text-white py-1 cursor-pointer rounded select-none">
        <Icon name="material-symbols:person"
              class="px-2" /><span>Profile</span>
      </nuxt-link>
      <div class="hover:bg-a-secondary hover:text-white border-t py-1 cursor-pointer rounded select-none"
           @click="authManager.logout('/login')">
        <Icon name="solar:logout-line-duotone"
              class="px-2" /><span>Logout</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const authManager = useAuthManager();
const showUserDropdown = ref(false);


// const authUser = computed(() => {
//   return authManager.authUser.value
// });

// const avatarUrl = computed(() => {
//   if (
//     authUser.value &&
//     authUser.value.avatar
//   ) {
//     return authUser.value.avatar;
//   } else {
//     return "/images/main/avatar-placeholder.png";
//   }
// });

// const firstName = computed(() => {
//   if (
//     authUser.value &&
//     authUser.value.first_name
//   ) {
//     return authUser.value.first_name;
//   }
// });

// const lastName = computed(() => {
//   if (
//     authUser.value &&
//     authUser.value.last_name
//   ) {
//     return authUser.value.last_name;
//   }
// });

// async function logoutUser() {
//   await authManager.logout('/login')
// }
</script>

<style></style>
